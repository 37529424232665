import { SYDNEY } from '@/constants/product-codes'

const anchorLink = ( text, link ) => {
    return <a href={link} target="_blank" class="t-link" data-selector='overview-link'>{text}</a>
}
const SHARED_LINKS = {
    BEDROOM_FURNITURE: anchorLink('bedroom furniture', '/furniture?types=platform+bed+frames%2Cstandard+bed+frames%2Cbenches+ottomans%2Cchairs'),
    STANDARD_BEDFRAME: anchorLink('standard bed frame', '/furniture?styles=standard+bed+frames'),
    PLATFORM_BEDFRAME: anchorLink('platform bed frame', '/furniture?styles=platform+bed+frames'),
    ADJUSTABLE_BASE: anchorLink('adjustable base', '/furniture?types=adjustable+bases'),
    ADJUSTABLE_BASE_UF_KING: anchorLink('Saatva Adjustable Base Plus Upper-Flex King', '/bases/adjustable-base-plus?sku=8105-5/0'),
    ADJUSTABLE_BASE_SPLIT_KING: anchorLink('Split King adjustable bases', '/furniture?types=adjustable+bases&sizes=split+king'),
    ADJUSTABLE_BASE_SPLIT_KINGS: anchorLink('Split King & Split Cal King adjustable bases', '/furniture?types=adjustable+bases&sizes=split+king%2Csplit+cal+king'),
    FOUNDATION: anchorLink('foundation', '/furniture/foundation'),
    SYDNEY: anchorLink('Sydney', `/furniture/${SYDNEY}`),
    SAATVA_CLASSIC: anchorLink('Saatva Classic', '/mattresses/saatva-classic'),
    ALL_STORAGE_BED_FRAMES: anchorLink('all storage bed frames', '/furniture?types=bed+frames&styles=storage+bed+frames'),
    ADJUSTABLE_BASE_UF_KING_BUNDLE: anchorLink('Saatva Adjustable Base Plus Upper-Flex King', '/bases/adjustable-base-plus?sku=8115-SQ-bundle')
}

export default SHARED_LINKS

export { anchorLink, SHARED_LINKS }
