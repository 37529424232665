import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { scrollToElement, OFFSETS } from '@saatva-bits/pattern-library.utils.position'

import styles from '../ProductOverviewAccordion.module.scss'
import SvgImage from '@/components/SvgImage'

const CHECKMARK_SVG = <SvgSprite spriteID='icon-checkmark' className={`${styles.bulletSprite} ${styles.green}`} />

export const CompatibleItems = ({itemsData}) => (
    <ul className={styles.compatibleList}>
        {itemsData.map( (itemData, i) => (
            <li key={`compatible-item-${i}`}>
                {CHECKMARK_SVG}
                <strong>{itemData.title}</strong>
                <p>
                    {itemData.body}
                </p>
            </li>
        ))}
    </ul>
)

export const FeatureIcons = ({featuresData}) => (
    <div className={styles.featuresWrapper}>
        {featuresData.map( feature => (
            <div key={feature.spriteID}>
                <SvgSprite spriteID={feature.spriteID} className={styles.featureSprite} />
                <strong>{feature.title}</strong>
            </div>
        ))}
    </div>
)

export const FirmnessBlock = ({spriteID, title, afterTitle, children}) => (
    <div>
        <SvgSprite className={styles.firmnessSprite} spriteID={spriteID} />
        <h4>{title} {afterTitle && <span className={styles.light}>{afterTitle}</span>}</h4>
        {children}
    </div>
)

export const CertificationLogos = ({ imageIDs }) => (
    <div className={styles.certLogosWrapper}>
        {imageIDs.map( imageID => (
            <SvgImage imageID={imageID} className={styles.certSprite} key={imageID} alt={`Certification icon for ${imageID.replace('icon-certification-', '')}`} />
        ))}
    </div>
)

export const Disclaimer = ({children}) => (
    <p className={styles.disclaimer}>
        {children}
    </p>
)

export const Callout = ({children}) => (
    <div className={styles.callout}>{children}</div>
)

export const ScrollLink = ({targetID, children}) => (
    <button className="t-link" data-selector="overview-link" onClick={() => scrollToElement(targetID, OFFSETS.both)}>
        {children}
    </button>
)
