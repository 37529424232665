import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'

import { Global } from '@/contexts'
import useScrollToFaqsAndSpecs from '@/hooks/useScrollToFaqsAndSpecs'

import { CONTRAST1, CONTRAST2 } from './ProductOverviewAccordionThemes'
import styles from './ProductOverviewAccordion.module.scss'
import productOverviewConfig from './config'

const ProductOverviewAccordion = ({ theme }) => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const { ScrollToFaqsAndSpecsButton } = useScrollToFaqsAndSpecs()

    const currentOverviewData = productOverviewConfig[productCode]
    if (!currentOverviewData) return null

    const accordionData = currentOverviewData.map((item, i) => {
        // The ID in this data structure is used as a suffix to customize the accordions IDs.
        // This is then leveraged in the `expandAccordionByIdSuffix` utility.
        // ie: `accordion-title-<id>` and `accordion-content-<id>`
        const idSuffix = item.heading.split(' ')[0].toLowerCase()
        return {
            id: idSuffix,
            title: item.heading,
            content: item.getContent(ScrollToFaqsAndSpecsButton),
            isOpenOnRender: i == 0
        }
    })

    const wrapperClasses = classNames(styles.wrapper, `section`, {
        [styles.contrast1]: theme === CONTRAST1,
        [styles.contrast2]: theme === CONTRAST2
    })
    const accordionSetClasses = classNames(styles.accordionSet, `containerCol--xs-12`)
    return (
        <div id="product-overview" data-selector='pdp-product-overview' className={wrapperClasses}>
            <div className="container" data-selector='overview-accordion'>
                <AccordionSet accordionData={accordionData} className={accordionSetClasses} >
                    <Accordion
                        className={styles.accordion}
                        titleClassName="t-heading3 t-color t-family--serif u-marginBottom--none"
                        titleContainerClassName={styles.accordionTitleContainer}
                        contentClassName={styles.accordionContent}
                        contentContainerClassName={styles.accordionContentContainer}
                    />
                </AccordionSet>
            </div>
        </div>
    )
}

ProductOverviewAccordion.propTypes = {
    /** Changing the background color of the section means that other coloring, such as the disclaimer background, needs to change */
    theme: PropTypes.oneOf([CONTRAST1, CONTRAST2])
}

export default React.memo(ProductOverviewAccordion)
